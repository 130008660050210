import ring from "../images/ring.png";
import CrystalStretchBracelet from "../images/Crystal-StretchBracelet.jpg";
import FlatLeatherBracelet from "../images/Flat-Leather-Slide-Bracelet.jpg";
import AdjustableBracelet from "../images/Adjustable-Suede-Bracelet.jpg";
import LeatherBracelet from "../images/Leather-Bracelet.jpg";
import ring_1 from "../images/ring_1.png";
import SustainableBracelet from "../images/Sustainable-Cork-Bracelet.jpg";
import MultiGemstone from "../images/Multi-Color-Gemstone.jpg";
import Mandala_logo from "../images/Mandala_logo.png";
import care from "../images/care.png";
const Images = {
  Mandala_logo,
  ring,
  CrystalStretchBracelet,
  FlatLeatherBracelet,
  AdjustableBracelet,
  LeatherBracelet,
  ring_1,
  SustainableBracelet,
  MultiGemstone,
  care,
};
export default Images;
