import React from "react";
import config from "../common/Config";
import Header from "./Header";
import banner from "../images/3_tile.jpg";
import Images from "../Component/Images";
import { useNavigate } from "react-router-dom";
import Footer from "../Component/Footer";
const Product = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="container product-wrapper">
        <div className="w-100 my-5 d-flex justify-content-center">
          <img
            alt=""
            className="center-block banner-img w-50"
            src={Images.care}
          />
        </div>

        <p class="mandDescp">
          <b>
            Bracelets On A Mission will make fundraising more
            personal,informative and memorable. Create Your Own Bracelet On A
            Mission and Show You Care! It’s easy and fun!
          </b>
        </p>

        <div class="head text_margin mt-5 px-4">
          <h3>Choose your favorite Style and Color:</h3>
          <p className="text-center">(2 style choices/ 3 color choices)</p>
        </div>

        <div className="row bracelet-row d-flex justify-content-center">
          <div
            className="col-sm-4 col-12 bracelets-item yes-marg"
            id={1}
            // onclick="bracelateVal(4)"
          >
            <div className="men-pro-item simpleCart_shelfItem selected">
              <div className="men-thumb-item">
                <img
                  src={Images.LeatherBracelet}
                  alt=""
                  className="pro-image-front"
                />
                <img
                  src={Images.LeatherBracelet}
                  alt=""
                  className="pro-image-back"
                />
              </div>
              <div className="item-info-product ">
                <h4>
                  <a href>Waterproof PU Leather Slide Bracelet</a>
                </h4>
                <div className="info-product-price">
                  <span className="item_price">$25</span>
                </div>
                <button
                  type="button"
                  className="choose-bracelet hvr-outline-out button2"
                >
                  Choose Style
                </button>
              </div>
            </div>
          </div>
          <div
            className="col-sm-4 col-12 bracelets-item yes-marg"
            id={2}
            // onclick="bracelateVal(3)"
          >
            <div className="men-pro-item simpleCart_shelfItem h-100">
              <div className="men-thumb-item">
                <img
                  src={Images.MultiGemstone}
                  alt=""
                  className="pro-image-front"
                />
                <img
                  src={Images.MultiGemstone}
                  alt=""
                  className="pro-image-back"
                />
              </div>
              <div className="item-info-product ">
                <h4>
                  <a href>Hematite Gemstone Stretch Bracelet</a>
                </h4>
                <div className="info-product-price">
                  <span className="item_price">$25</span>
                </div>
                <button
                  type="button"
                  className="choose-bracelet hvr-outline-out button2"
                >
                  Choose Style
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row bracelet-row d-flex justify-content-center">
          <div
            className="col-sm-4 col-12 bracelets-item yes-marg"
            id={3}
            // onclick="bracelateVal(1)"
          >
            <div className="men-pro-item simpleCart_shelfItem h-100">
              <div className="men-thumb-item">
                <img
                  src={Images.CrystalStretchBracelet}
                  className="pro-image-front"
                  alt=""
                />
                <img
                  src={Images.CrystalStretchBracelet}
                  className="pro-image-back"
                  alt=""
                />
              </div>
              <div className="item-info-product ">
                <h4>
                  <a href>
                    Sustainable Cork <br /> Bracelet
                  </a>
                </h4>
                <div className="info-product-price">
                  <span className="item_price">$35</span>
                </div>
                <button
                  type="button"
                  className="choose-bracelet hvr-outline-out button2"
                >
                  Choose Style
                </button>
              </div>
            </div>
          </div>
          <div
            className="col-sm-4 col-12 bracelets-item yes-marg"
            id={4}
            // onclick="bracelateVal(2)"
          >
            <div className="men-pro-item simpleCart_shelfItem h-100">
              <div className="men-thumb-item">
                <img
                  src={Images.AdjustableBracelet}
                  alt=""
                  className="pro-image-front"
                />
                <img
                  src={Images.AdjustableBracelet}
                  alt=""
                  className="pro-image-back"
                />
              </div>
              <div className="item-info-product ">
                <h4>
                  <a href>
                    Adjustable Suede <br /> Bracelet
                  </a>
                </h4>
                <div className="info-product-price">
                  <span className="item_price">$25</span>
                </div>
                <button
                  type="button"
                  className="choose-bracelet hvr-outline-out button2"
                >
                  Choose Style
                </button>
              </div>
            </div>
          </div>

          <div className="clearfix" />
        </div>

        <div className="select-size mt-5 px-4">
          <div class=" head text_margin">
            <h3>Indicate Your Size</h3>
          </div>
          <div class="">
            <div class="bracelet-size">
              <h4>Bracelet Size Chart (Click to choose)</h4>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-6 col-xs-12 px-0 pe-sm-3 pe-0">
              <div className="bracelet-size" id="women-size">
                <h4>Women’s Size</h4>
                <select id="w-size" className="w-100 p-3">
                  <option value="">Please select</option>
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                </select>
              </div>
            </div>
            <div className="col-sm-6 col-xs-12 px-0">
              <div className="bracelet-size" id="men-size">
                <h4>
                  Men’s Size
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ display: "none" }}
                    data-toggle="modal"
                    data-target="#mensizeModal"
                  >
                    (Size Chart)
                  </button>
                </h4>
                <select className="w-100 p-3" name="" id="">
                  <option value="">Please select</option>
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                </select>
              </div>
            </div>
          </div>
          <div id="msg" style={{ marginTop: 35 }} hidden>
            <button id="msgBox" style={{ marginLeft: 400 }} />
          </div>
        </div>
        <div class="charity-link mt-5 px-4 mb-5 text-center">
          <div class="head text_margin">
            <h3>Personalized Link To Charity</h3>
            <div class="charity-input">
              <p className="text-center">
                Now, simply provide the URL for a nonprofit or fundraising event
                (online Donate Now Page required) to link your bracelet with
                your cause of choice
              </p>
              <br />
              <input
                type="text"
                class="form-control"
                id="charity_link"
                placeholder="Enter Charity Link"
              />
            </div>
          </div>
          <button
            className="pay-btn cart-toggle mt-5"
            id="pay-btn"
            type="button"
            onClick={() => navigate("/cart")}
          >
            ADD TO CART
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Product;
