import logo from './logo.svg';
import './App.css';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Home from "./screens/Home";
import Product from "./screens/Product";
import Cart from "./Component/Cart";
import BraceletsPayment from "./screens/BraceletsPayment";
import About from "./screens/About";
function App() {
  return (
    <>
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/about" element={<About />} />
          <Route path="/braceletsPayment" element={<BraceletsPayment />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
