import React from "react";
import config from "../common/Config";
import Images from "../Component/Images";
const About = () => {
  return (
    <div className="container about-container">
      <h2 className="mt-4">ABOUT US</h2>
      <h2 className="mission-text my-4 text-center">OUR MISSION</h2>

      <p class="mandDescp mandFirst">
        We created Bracelets On A Mission to provide an updated and improved
        version of the well-loved awareness bracelet to benefit charitable
        organizations and fundraising events. Our goal is to offer a new way for
        people to become advocates for the causes they care about while making
        the donation experience more meaningful and memorable than the current
        options.
      </p>

      <section className="mt-3 d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex justify-content-center">
          <a href={config.appBasePath}>
            <img
              className="head-logo"
              src={Images.Mandala_logo}
              style={{ backgroundColor: "#353e5b" }}
              alt=""
            />
          </a>
        </div>
        <a href={config.appBasePath}>
          <img
            className="head-lg"
            src="http://braceletsonamission.com/assets/images/logos/Mandala_txt.png"
            alt=""
          />
        </a>
        <br />
      </section>

      <p class="mandDescp mandFirst">
        The mandala, an enduring symbol of enlightenment and compassion, created
        by the Tibetan and Celtic cultures. Our beautiful, eye-catching mandala
        logo will prompt questions and inspire meaningful conversations and
        support for charitable causes.
      </p>
    </div>
  );
};

export default About;
