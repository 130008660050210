import React from "react";
import ring from "../images/ring.png";
import ring1 from "../images/ring_1.png";
import image1 from "../images/2_tile.jpg";
import image from "../images/image.png";
import group from "../images/group.png";
import bracelet from "../images/bracelet.png";
import Header from "./Header";
import Footer from "../Component/Footer";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <>
      <Header />
      <div className="container-fluid d-flex flex-column mt-5">
        <div className="row" id="introSection">
          <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
            <center>
              <h1 className="blue_clr">
                Introducing <br />
                <b className="font-web">Bracelets On A Mission</b>
              </h1>
            </center>
            <br />
            <div className="col-md-6 col-md-offset-3">
              <img alt="" className="center-block intro_img" src={bracelet} />
              <br />
              <br />

              <p className="mandDescp">
                <b>It’s A Whole New Way to Make Fundraising</b>
              </p>
              <p className="mandDescp">
                <b>Easy, Engaging and Fun!</b>
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="text-center">
            <p className="h3 mt p-width">
              Design Your Own Bracelet On A Mission and Start Collecting
              Donations for your
            </p>
            <p className="h3 p-width" style={{ marginTop: "1rem" }}>
              favorite cause or fundraising event.
            </p>
            <br />
          </div>
        </div>
        <div className="container ring">
          <br />
          <div className="row d-flex justify-content">
            <div className="col-md-4 text-center">
              <div className="side_image 2-img img-2">
                <img alt="" className="center-block" src={ring} />
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="side_image 2-img img-2">
                <img alt="" className="center-block" src={ring1} />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="text-center">
            <p className="h3 align-left p-width">Here’s How:</p>
            <p className="h3 align-left p-width mt">
              Our great looking bracelets, featuring an eye-catching mandala
              logo will prompt conversations about the cause you care about. On
              the flip side of the mandala is a scannable code, providing an
              easy way to collect donations anytime and anywhere.
              <br />
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row d-flex justify-content make-diff">
            <div className="col-md-7">
              {/* <div className="side_image 2-img img-2">
                <img
                  alt=""
                  className="center-block banner-img"
                  src={Images.care}
                />
              </div> */}
              <br />
              <p class="mandDescp">
                <b>
                  <p class="mandDescp">
                    <b>100 % of every donation goes to the charity.</b>
                  </p>
                </b>
              </p>
            </div>
          </div>
        </div>

        <div className="container mt our_mission">
          <div className="text-center">
            <p className="mandDescp blue_clr">
              <b>Our Mission</b>
            </p>
            <p className="mandDescp">
              <b>We’re Taking Awareness Bracelets to A Whole New Level!</b>
            </p>
          </div>
        </div>
        <div className="container">
          <br />
          <div className="row d-flex justify-content level-image">
            <div className="col-sm-4 col-12 text-center">
              <p className="mandDescp align-left">
                <b>Inspire Generosity </b>
              </p>
              <div className="side_image 2-img img-2">
                <img alt="" className="center-block" src={image1} />
              </div>
              <br />
            </div>
            <div className="col-sm-4 col-12 text-center">
              <p className="mandDescp align-left">
                <b>Wear Your Cause</b>
              </p>
              <div className="side_image 2-img img-2">
                <img alt="" className="center-block" src={image} />
              </div>
              <br />
            </div>
          </div>
        </div>
        <h2 className="enthusiasm">
          <p className="mandDescp mandFirst">
            <b>Share your Enthusiasm</b>
          </p>
        </h2>
        <div class="row d-flex justify-content-center">
          <div className="col-md-6 col-md-offset-3">
            <img alt="" className="center-block intro_img" src={group} />

            <br />
            <p className="mandDescp mandFirst">
              <b>Opportunity Is Everywhere</b>
            </p>
          </div>
        </div>

        <div className="m-t-20" />
        <div className="row d-flex justify-content-center">
          <div className="cpl-sm-8 col-md-8 col-md-offset-2 ">
            <div className="content side_cont">
              <div className="animated fadeInUp">
                <div className="label center-block other_label">
                  <Link
                    to="/product"
                    className="learn_cls otherLearn_cls contDiv-btn me-3"
                  >
                    Start Designing
                    {/* PRODUCTS &amp; PRICING */}
                  </Link>
                  <Link
                    to="/about"
                    className="learn_cls otherLearn_cls contDiv-btn"
                  >
                    About Us
                    {/* PRODUCTS &amp; PRICING */}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        {/* footer */}
      </div>
      <Footer />
    </>
  );
};

export default Home;
