import React from "react";
import config from "../common/Config";
import Images from "../Component/Images";


const Header = () => {
  return (
    <div className="container-fluid imgbg">
      <div class="img-style d-flex justify-content-center align-items-end ">
        <p className="h1 bracelets-text">
          <b>Bracelets</b>
        </p>
        <div className="">
          <a href={config.appBasePath} className="text-none">
            <img
              className="logo-imgs center-block "
              src={Images.Mandala_logo}
              alt=""
            />
          </a>
        </div>
        <p className="h1 bracelets-text">
          <b>On A Mission </b>
          <sup>TM</sup>
        </p>
      </div>

      <div className="logo-bottom-text">
        <a href="#">
          Show You Care <sup>®</sup>
        </a>
      </div>
      <div className="header header_wrapper menu-header">
        <div className="container">
          <div className="menu-row">
            <div className="head-info">
              <span className="glyphicon" aria-hidden="true" />
              Bracelets On A Mission | Non-Profit Partners
            </div>
            <div className="header-login">
              <a href="#">Login</a> |<a href="#">Register</a>
            </div>
            <div className="main-nav">
              <ul>
                <li>
                  <a href="#">Our Mission | Start Designing</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
