import React from "react";
import config from "../common/Config";
import Images from "./Images";

const Footer = () => {
  return (
    <>
      <div className="container-fluid imgbg">
        <div class="img-style d-flex justify-content-center align-items-end ">
          <p className="h1 bracelets-text">
            <b>Bracelets</b>
          </p>
          <div className="">
            <a href={config.appBasePath} className="text-none">
              <img
                className="logo-imgs center-block "
                src={Images.Mandala_logo}
              />
            </a>
          </div>
          <p className="h1 bracelets-text">
            <b>On A Mission </b>
            <sup>TM</sup>
          </p>
        </div>
        <div className="logo-bottom-text">
          <a href="#">
            Show You Care <sup>®</sup>
          </a>
        </div>
        <div className="row">
          <div className="col-md-12 header-right footer-bottom  symbols">
            <ul>
              <li>
                <div className="ic_class">
                  <a
                    href="https://www.facebook.com/BraceletsOnMission"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook ic-size" />
                  </a>
                </div>
              </li>
              <li>
                <div className="ic_class">
                  <a
                    href="https://www.instagram.com/braceletsonamission/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram ic-size" />
                  </a>
                </div>
              </li>
              <li>
                <div className="ic_class">
                  <a
                    href="https://twitter.com/BraceletMission"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-twitter ic-size" />
                  </a>
                </div>
              </li>
              <li>
                <div className="ic_class">
                  <a
                    href="https://in.pinterest.com/BraceletsOnAMission/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-pinterest ic-size" />
                  </a>
                </div>
              </li>
              <li>
                <div className="ic_class">
                  <a
                    href="https://www.youtube.com/channel/UC1rMPyd4IeXoAx2Hr0TiGtQ/featured"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-youtube ic-size" />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
