import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Cart = () => {
  const navigate = useNavigate();
  return (
    <>
      <div id="cart-dtl">
        <div class="row d-flex justify-content-center p-0 m-0">
          <div class="col-md-8 col-12 p-3 d-flex justify-content-center">
            <div className="prod-det-item">
              <div className="prod-header">
                <div className="prod-image">
                  <img
                    className="img-fluid"
                    src="http://braceletsonamission.com/assets/images/bracelets/Flat-Leather-Slide-Bracelet.jpg"
                  />
                </div>
                <div className="position-relative pord-name">
                  <h5>Waterproof PU Leather Slide Bracelet</h5>
                  <p className="prd-price">Price: $25</p>
                  <p className="prd-size">Size: S</p>
                </div>
              </div>
              <div className="cart-middle">
                <span className="close-card">
                  <button type="button" onclick="removeProduct(0)">
                    Remove
                  </button>
                </span>
                <div className="quantity">
                  {" "}
                  <button type="button" onclick="removeClick(0)">
                    -
                  </button>
                  <input
                    type="tel"
                    className="inc"
                    id="inc_0"
                    min={1}
                    defaultValue={1}
                  />
                  <button type="button" onclick="buttonClick(0)">
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button
            class="btn-checkout"
            id="btn-checkout"
            type="button"
            onClick={() => navigate("/braceletsPayment")}
          >
            CHECKOUT
          </button>
        </div>
      </div>
    </>
  );
};

export default Cart;
